<template>
  <div>
    <Loader
      v-if="loaderEnabled"
      name="EditTransferStoreForm"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Cập nhật phiếu chuyển cửa hàng'">
      <template v-slot:preview>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <label class="mt-5"> Tên, Mã NV: </label>
              <b-form-input
                size="sm"
                v-model="employeeName"
                placeholder="Tên nhân viên"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label class="mt-5"> Cửa hàng hiện tại: </label>
              <b-form-input
                size="sm"
                v-model="currentStoreName"
                placeholder="Cửa hàng hiện tại"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label class="mt-5"> Cửa hàng muốn chuyển đến: </label>
              <b-form-input
                size="sm"
                v-model="targetStoreName"
                placeholder="Cửa hàng muốn chuyển đến"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <label class="mt-5"> Thông tin tạo yêu cầu: </label>
              <b-form-input
                size="sm"
                v-model="getCreator"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label class="mt-5"> Thông tin cập nhật yêu cầu: </label>
              <b-form-input
                size="sm"
                v-model="getApprover"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label class="mt-5"> Ghi chú: </label>
              <b-form-textarea
                id="textarea"
                size="sm"
                v-model="description"
                placeholder="Lý do chuyển cửa hàng"
                rows="3"
                max-rows="6"
                disabled
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="3">
            <b-form-group>
              <label class="mt-5">Trạng thái yêu cầu: </label>
              <span class="ml-4"><StatusForm :status="status" /></span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="d-flex justify-content-between ml-0">
          <div>
            <b-button variant="primary" size="sm" @click="$router.go(-1)"
              >Quay lại</b-button
            >
          </div>
          <div
            v-if="
              status === statusEnum.PENDING &&              
             checkPermission('EDIT_TRANSFER_STORE_FORM')
            "
          >
            <b-button variant="success" size="sm" v-b-modal.modal-approve
              >Duyệt</b-button
            >
            <b-button  class="ml-2" variant="primary" size="sm" v-b-modal.modal-approve
              >Duyệt và chuyển</b-button
            >
            <b-button
              variant="warning"
              size="sm"
              class="ml-2"
              v-b-modal.modal-reject
              >Từ chối</b-button
            >
            <b-button
              variant="danger"
              size="sm"
              class="ml-2"
              v-b-modal.modal-delete
              >Xoá đơn</b-button
            >
          </div>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal
      ref="modal-approve"
      id="modal-approve"
      title="Bạn có đồng ý duyệt đơn không?"
      hide-footer
    >
      <div>
        <p>
          <span style="font-weight: 500">Họ tên nhân viên:</span>
          {{ employeeName }}
          {{ employeeCode ? `(${employeeCode})` : '' }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng hiện tại:</span>
          {{ currentStoreName }}
        </p>
        <p>
          <span style="font-weight: 500">Cửa hàng yêu cầu chuyển đến:</span>
          {{ targetStoreName }}
        </p>
      </div>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalApprove" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleApprove"
          variant="success"
          class="ml-3"
          size="sm"
          >Tiến hành duyệt</b-button
        >
      </div>
    </b-modal>

    <b-modal
      ref="modal-reject"
      id="modal-reject"
      title="Bạn có đồng ý từ chối yêu cầu không?"
      hide-footer
    >
      <p>
        <span style="font-weight: 500">Họ tên nhân viên:</span>
        {{ employeeName }}
        {{ employeeCode ? `(${employeeCode})` : '' }}
      </p>
      <p>
        <span style="font-weight: 500">Cửa hàng hiện tại:</span>
        {{ currentStoreName }}
      </p>
      <p>
        <span style="font-weight: 500">Cửa hàng yêu cầu chuyển đến:</span>
        {{ targetStoreName }}
      </p>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalReject" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleReject"
          variant="warning"
          class="ml-3"
          size="sm"
          >Tiến hành từ chối</b-button
        >
      </div>
    </b-modal>
    <b-modal
      ref="modal-delete"
      id="modal-delete"
      title="Bạn có đồng ý từ chối yêu cầu không?"
      hide-footer
    >
      <p>
        <span style="font-weight: 500">Họ tên nhân viên:</span>
        {{ employeeName }}
        {{ employeeCode ? `(${employeeCode})` : '' }}
      </p>
      <p>
        <span style="font-weight: 500">Cửa hàng hiện tại:</span>
        {{ currentStoreName }}
      </p>
      <p>
        <span style="font-weight: 500">Cửa hàng yêu cầu chuyển đến:</span>
        {{ targetStoreName }}
      </p>
      <div class="d-flex justify-content-start">
        <b-button @click="hideModalDelete" variant="primary" size="sm"
          >Quay lại</b-button
        >
        <b-button
          @click="onHandleDelete"
          variant="danger"
          class="ml-3"
          size="sm"
          >Tiến hành xoá đơn</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import {
getEmployeeFromLocal, makeToastFaile,
makeToastSuccess
} from '@/utils/common';
import {
JOB_TITLE,
JOB_TITLE_APPROVE,
TRANSFER_STORE_FORM_STATUS
} from '@/utils/enum';
import { checkPermission } from '@/utils/saveDataToLocal';
import StatusForm from '@/view/components/transfer-store-forms/StatusForm.vue';
import KTCodePreview from '@/view/content/CodePreview.vue';
import Loader from '@/view/content/Loader.vue';
import { mapGetters } from 'vuex';
import localData from '@/utils/saveDataToLocal';


export default {
  components: { KTCodePreview, StatusForm, Loader },
  data() {
    return {
      employeeName: '',
      employeeCode: '',
      currentStoreName: '',
      currentStoreId: null,
      targetStoreName: '',
      targetStoreId: null,
      status: 1,
      description: '',
      createdAt: '',
      approvedAt: '',
      createdBy: '',
      createdByName: '',
      approvedBy: '',
      approvedByName: '',
      formId: this.$route.query.id,
      employeeId: null,
      jobTitleId: null,
      statusEnum: TRANSFER_STORE_FORM_STATUS,
      permission: {
        edit: 'EDIT_TRANSFER_STORE_FORM',
      },
    };
  },
  created() {
    this.fetchTransferStoreById();
    this.userInfo = getEmployeeFromLocal();
    const employeeInfo = JSON.parse(
      window.localStorage.getItem('employee_info')
    );

    this.employeeId = employeeInfo?.employeeId || null;
    this.jobTitleId = employeeInfo?.jobTitleId || null;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Phiếu yêu cầu chuyển cửa hàng',
        route: '/transfer-store-forms',
      },
      { title: 'Cập nhật' },
    ]);
    this.formId = this.$route.query.id;
  },
  methods: {
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    hideModalApprove() {
      this.$refs['modal-approve'].hide();
    },
    hideModalReject() {
      this.$refs['modal-reject'].hide();
    },
    hideModalDelete() {
      this.$refs['modal-delete'].hide();
    },
    fetchTransferStoreById() {
      ApiService.query(`transfer-store-forms/${this.formId}`)
        .then((res) => {
          const { data } = res.data;
          this.employeeName = data.employeeName;
          this.employeeCode = data.employeeCode;
          this.currentStoreName = data.currentStoreName;
          this.currentStoreId = data.currentStoreId;
          this.targetStoreName = data.targetStoreName;
          this.targetStoreId = data.targetStoreId;
          this.status = data.status;
          this.description = data.description;
          this.createdAt = data.createdAt;
          this.approvedAt = data.approvedAt;
          this.createdBy = data.createdBy;
          this.createdByName = data.createdByName;
          this.approvedBy = data.approvedBy;
          this.approvedByName = data.approvedByName;
        })
        .catch((err) => makeToastFaile(err.message));
    },
    onHandleApprove() {
      const reqData = {
        status: this.statusEnum.APPROVED,
      };
      ApiService.update('transfer-store-forms', this.formId, reqData)
        .then((res) => {
          makeToastSuccess(res.data.message);
          this.fetchTransferStoreById();
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => this.$refs['modal-approve'].hide());
    },
    onHandleReject() {
      const reqData = {
        status: this.statusEnum.REJECTED,
      };
      ApiService.update('transfer-store-forms', this.formId, reqData)
        .then((res) => {
          makeToastSuccess(res.data.message);
          this.fetchTransferStoreById();
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => this.$refs['modal-reject'].hide());
    },
    onHandleDelete() {
      ApiService.delete(`transfer-store-forms/${this.formId}`)
        .then((res) => {
          makeToastSuccess(res.data.message);
        })
        .catch((err) => {
          makeToastFaile(err.response.data.message);
        })
        .finally(() => {
          this.$refs['modal-delete'].hide();
          this.$router.go(-1);
        });
    },
    checkRoleAllowEdit(createdBy, targetStoreId) {
      if (this.jobTitleId === 1) return true;
      if (
        !checkPermission(this.permission.edit) ||
        this.employeeId === createdBy
      ) {
        return false;
      }
      return this.checkPermissionAllowUpdate(targetStoreId);
    },
    checkPermissionAllowUpdate(targetStoreId) {
      const { listStoreId, jobTitleId } = this.userInfo;
      const listStoreIdArray = listStoreId.split(', ');

      if (
        jobTitleId === JOB_TITLE.GD ||
        (JOB_TITLE_APPROVE.includes(jobTitleId) &&
          listStoreIdArray.includes(targetStoreId.toString()))
      ) {
        return true;
      }

      return false;
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    getCreator() {
      return `${this.createdByName} - ${this.createdAt}`;
    },
    getApprover() {
      if (!this.approvedBy) return '';
      return `${this.approvedByName} - ${this.approvedAt}`;
    },
    checkAllowEdit() {
      return checkPermission(this.permission.edit);
    },
  },
};
</script>

<style>
#modal-approve {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
}

.modal-dialog {
  transform: none;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
</style>
